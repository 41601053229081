import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout/Layout'
import Blocks from '@/lib/blocks/Blocks'
import MetaInfo from '@/layout/MetaInfo'

const BlogListing = ({ data: { pg } }) => (
	<Layout>
		<Blocks blocks={pg.blocks} />
	</Layout>
)

export default BlogListing

export const Head = MetaInfo

export const query = graphql`query BlogListing {
	pg: sanityBlogTemplate(metaInfo: {slug: {current: {eq: "blog"}}}) {
		blocks {
			...blogListing
			...blogSearch
			...footerCtaSection
		}
		metaInfo { ...metaInfo }
	}
}`
